// @import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800');
@import "font-face-mixin.scss";
@include font-face("Raleway", "Bold", $packageKey: "Rothstein.MainWebsite");
@include font-face("Raleway", "BoldItalic", $packageKey: "Rothstein.MainWebsite");
@include font-face("Raleway", "ExtraBold", $packageKey: "Rothstein.MainWebsite");
@include font-face("Raleway", "ExtraBoldItalic", $packageKey: "Rothstein.MainWebsite");
@include font-face("Raleway", "Italic", $packageKey: "Rothstein.MainWebsite");
@include font-face("Raleway", "Light", $packageKey: "Rothstein.MainWebsite");
@include font-face("Raleway", "LightItalic", $packageKey: "Rothstein.MainWebsite");
@include font-face("Raleway", "Medium", $packageKey: "Rothstein.MainWebsite");
@include font-face("Raleway", "MediumItalic", $packageKey: "Rothstein.MainWebsite");
@include font-face("Raleway", "Regular", $packageKey: "Rothstein.MainWebsite");
@include font-face("Raleway", "SemiBold", $packageKey: "Rothstein.MainWebsite");
@include font-face("Raleway", "SemiBoldItalic", $packageKey: "Rothstein.MainWebsite");

html {
    position: relative;
    min-height: 100%;
    overflow-x: hidden;
    body {
        margin: 0 0 20px;
        padding: 0;
        font-family: 'Raleway', sans-serif;
        h1{
            font-size: 20px;
        }
        h2{
            font-size: 16px;
        }
        h3{
            font-size: 14px;
        }
        h4{
            font-size: 14px;
        }
        p, a{
            font-size: 12px;
        }
        .informationIconWrapper i.fa{
            font-size: 30px;
            margin-bottom: 10px;
        }
        .divider{
            border-bottom: 1px solid #cecece;
            margin: 20px 0px;
        }
        .animate-hidden{
            opacity: 0;
        }
        a{
            text-decoration: none;
        }
        .bigImage{
            width: 100%;
            margin-bottom: 20px;
        }
    /*
		.cc-link{
			font-size: 16px;
			color: black !important;
			text-decoration: none !important;
			opacity: 1;
		}
    */
    .modal {
        z-index: 99999;
    }


    div.pkcp {
        >.pkcp-details {
          >.inner {
            >.header{
              >button{
                &.navbar-toggle {
                  float: none;
                }
              }
              >.title {
                @media(max-width: 399px){
                  font-size: 14px;
                  line-height: 2.2;
                }
              }
            }
          }
        }
      }
    

		.hidden-label{
			display: none;
		}
        @import 'header.scss';
        @import 'table.scss';
        @import 'form.scss';
        @import 'double-image-link.scss';
        @import 'section.scss';
        @import 'information-icon.scss';
        @import 'modal.scss';
        @import 'gallery.scss';
        @import 'centeredCollection.scss';
        @import 'doubleSlider.scss';
        @import 'asset-list.scss';

        footer {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 60px;
            padding-top: 10px;
            background-color: #eee;
        }
        p{
            line-height: 1.5;
        }
        h3{
            line-height: 1.2;
        }

        // Responsive adjustments
        @media (min-width: $sm-view) {
            h1{
                font-size: 24px;
            }
            h2{
                font-size: 18px;
            }
            h3{
                font-size: 16px;
            }
            h4{
                font-size: 16px;
            }
            p,a{
                font-size: 14px;
            }
            .informationIconWrapper i.fa{
                font-size: 32px;
            }
            .bigImage{
                margin-bottom: 30px;
            }
        }

        @media (min-width: $md-view) {
            h1{
                font-size: 26px;
            }
            h2{
                font-size: 20px;
            }
            h3{
                font-size: 18px;
            }
            h4{
                font-size: 18px;
            }
            p,a{
                font-size: 16px;
            }
            .informationIconWrapper i.fa{
                font-size: 34px;
            }
            .bigImage{
                margin-bottom: 40px;
            }
        }

        @media (min-width: $lg-view) {
            h1{
                font-size: 30px;
            }
            h2{
                font-size: 24px;
            }
            h3{
                font-size: 22px;
            }
            h4{
                font-size: 22px;
            }
            p,a{
                font-size: 18px;
            }
            .informationIconWrapper i.fa{
                font-size: 40px;
            }
            .bigImage{
                margin-bottom: 50px;
            }
        }
    }
}
