.modal-wrapper {
    display: inline-block;
    margin: 0 10px;
}
.modal .modal-content {
    border-radius: 0;
    color: #525252;
    font-size: 13px;
    text-align: left;
}
.modal .modal-content .modal-header {
    border-bottom: none;
}
.modal .modal-content a {
    color: #525252;
}
.modal .modal-content hr { 
    border-color: #525252;
}
.modal-header .close{
    font-size: 30px;
}
   