.CrazySlider1, .CrazySlider2{
    margin: 0px -15px;
}
.CrazySlider1{
    position: absolute;
    width: 125%;
    left: -15px;
    top: 0px;
}
.CrazySlider1 img{
    -webkit-clip-path: polygon(75% 0, 100% 50%, 75% 100%, 0% 100%, 0 50%, 0% 0%);
    -ms-clip-path: polygon(75% 0, 100% 50%, 75% 100%, 0% 100%, 0 50%, 0% 0%);
    -o-clip-path: polygon(75% 0, 100% 50%, 75% 100%, 0% 100%, 0 50%, 0% 0%);
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 0% 100%, 0 50%, 0% 0%);
    width: 150% !important;
    height: 610.73px;
}
.CrazySlider2 img{
    -webkit-clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 30% 50%, 0% 0%);
    -ms-clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 30% 50%, 0% 0%);
    -o-clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 30% 50%, 0% 0%);
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 30% 50%, 0% 0%);
}