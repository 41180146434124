@media (max-width: $sm-view) {
.flexCollection{
    height: auto !important;
}
}
@media (min-width: $sm-view) {
.flexCollection{
    display: flex;
    position: absolute;
    height: auto;
    top: 0px;
    left: 0px;
    width: 100%;
    align-items: center;
}
}