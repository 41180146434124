.imgDoubleImageLink{
    width: 100%;
}
.blackLayer{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    background-color: black;
    opacity: 0.4;
    transition: all 0.5s;
    z-index: 10;
}
.DoubleImageLinkWrapper{
    margin: 0 -15px;
    position: relative;
    .img-responsive{
        display: inline-block;
    }
}
.DoubleImageLinkContentWrapper{
    width: 100%;
    margin-top: -25%;
    left: 0;
    text-align: center;
    position: relative;
}
.DoubleImageLinkWrapperOfHover:hover .blackLayer:hover{
    opacity: 0;
    transition: all 0.5s;
}
@media (min-width: $sm-view) {
}

@media (min-width: $md-view) {}

@media (min-width: $lg-view) {}
