.gallery{
    margin: 0px;
    .gallery-item{
        padding: 0px 0px 10px;
    }
    @media (min-width: $sm-view) {
        margin: 0px -5px;
        .gallery-item{
            padding: 0px 5px 10px;
            position:relative;
            a{
                height: 100%;
            }
        }
    .gallery-item a:after{
        content: '';
        position: absolute;
        top: 0px;
        left: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        transition: 1s all;
    }
    .gallery-item a:hover:after{
        transition: 1s all;
        background-color: rgba(0,0,0,0.8);
        cursor: pointer;
    }
    }
}
