.ajax-form {
  text-align: center;

  i.fa-spinner {
    margin-top: 50px;
    font-size: 40px;
  }
  .control-label{
      font-weight: 600;
      text-align: left;
      padding: 0px;
  }
  span{
      font-weight: 600;
  }
  .next.submit{
      width:100%;
      .btn{
          width: 100%;
          background-color: #f2f2f2;
          text-transform: uppercase;
          border-color: #a3a3a3;
          margin-top: 20px;
      }
      .btn:hover{
          background-color: #f2f2f2;
      }
  }
  #contact-first-message, #contact-second-message{
      height: 99px;
  }
  .form-group{
      margin: 0px;
  }
  .form-control{
      background-color: #f2f2f2;
      border-color: #a3a3a3;
      border-radius: 1px;
      box-shadow: none;
  }
    .form-control:focus{
        border-color: #a3a3a3;
        box-shadow: none;
    }
  .list-unstyled{
      margin-bottom:0px;
      li{
          float: left;
          width: 50%;
          padding: 7px 0px;
          span{
              margin-right: 15px;
          }
      }
      li:first-child{
          padding-right: 20px;
          text-align: right;
      }
      li:last-child{
          padding-left: 20px;
          text-align: left;
      }
  }
  .formsection{
      margin-bottom: 0px;
  }
  #contact-first-sex, #contact-second-sex{
	display: inline-block;
	margin-bottom: 5px;
  }
  .help-block{
	font-size: 14px;
	padding: 0px;
	margin-top: 32px;
	text-align: left !important;
	a{
		font-size: 14px;
	}
  }
  .checkbox input{
	margin-top: 30px;
  }
  #contact-first-section-one, #contact-second-section-one {
	  text-align: left;
      .col-md-4{
          .control-label{
              display: block;
          }
      }
      .col-md-8{
          .control-label{
              padding: 0px 0px;
          }
      }
  }
  .xlarge{
      float: right;
  }
  .required{
      display: none;
  }
  #contact-first-company, #contact-second-company{
      width: 100%;
  }
  textarea {
    resize: none;
}
.actionWrapper{
    padding: 0px 15px;
    clear: both;
}
@media (min-width: $sm-view) {
    .btn.btn-default{
        position: absolute;
        top: -44px;
        left: 0px;
    }
    .actionWrapper{
        width: 50%;
        margin-left: 50%;
    }
    #contact-first-section-five, #contact-second-section-five {
        margin-top: 13px;
    }
  .list-unstyled{
      margin-bottom:0px;
      li{
          float: left;
          padding: 40px 0px 0px;
          width: auto;
          span{
              margin-right: 5px;
          }
      }
      li:first-child{
          padding-right: 10px;
      }
      li:last-child{
          padding-left: 0px;
      }
	}
	.help-block{
		text-align: left !important;
		margin-left: -20px;
	}
  .formsection{
      margin-bottom: 10px;
  }
  #contact-first-section-one, #contact-second-section-one{
      .col-md-4{
          .control-label{
              display: block;
          }
      }
      .col-md-8{
          .control-label{
              padding: 7px 0px;
          }
      }
  }
    #contact-first-section-six, #contact-second-section-six {
        margin-top: 10px;
    }
  .form-navigation{
      margin-top: -45px;
  }
  .btn{
      margin-top: 0px !important;
  }
}
@media (min-width: $md-view) {
    #contact-first-section-five, #contact-second-section-five {
        margin-top: 0px;
    }
    #contact-first-section-one, #contact-second-section-one {
        margin-top:20px;
    }
  .list-unstyled{
      margin-bottom:0px;
      li{
          float: left;
          padding: 7px 0px;
          span{
              margin-right: 15px;
          }
      }
      li:first-child{
          padding-right: 10px;
      }
  }
    #contact-first-company, #contact-second-company{
        width: 100%;
    }
    #contact-first-section-six, #contact-second-section-six{
        margin-top: 15px;
    }
  #contact-first-section-one, #contact-second-section-one {
      .col-md-4{
          .control-label{
              display: none;
          }
      }
      .col-md-8{
          .control-label{
              padding: 7px 0px;
          }
      }
  }
}

@media (min-width: $lg-view) {
  .list-unstyled{
      margin-bottom:0px;
      li{
          float: left;
          padding: 7px 0px;
          span{
              margin-right: 15px;
          }
      }
      li:first-child{
          padding-right: 40px;
      }
  }
}
}
