.assetlist
{
	margin: 10px 0 20px 0;
	text-align: center;
}
.assetlist div
{
	list-style-type: none;
	padding-left: 0px;
	text-align: center;
	display: inline-block;
	margin: 0 10px;
}
.assetlist i
{
	font-size: 57px;
        padding: 5px 0px;
}
.assetlist span{
    font-weight: 800;
}