.swiper-container{
    margin-bottom: 20px;
}
section{
    margin-bottom:20px;
    h1,h2,h3,h4{
        text-transform: uppercase;
        font-weight: 800;
    }
    p{
        font-weight: 300;
    }
    a{
        font-weight: 500;
    }
    a:hover{
        text-decoration: none;
    }
    a:focus{
        text-decoration: none;
        outline: none;
    }
    &.white-bg{
        background-color: white;
    }
    &.grey-bg{
        background-color: #878684;
    }
    &.red-bg{
        background-color: #c70000;
    }
    &.orange-bg{
      background-color: #ea5b0c;
    }
    &.dark-text{
        p, a{
            color: #3c3c3b;
        }
        h1, h2{
            color: #3c3c3b;
        }
        h3, h4{
            color:#c70000;
        }
    }
    &.light-text{
        p, a{
            color: white;
        }
        h1, h2, h3, h4{
            color:white;
        }
    }
    &:last-of-type{
        p{
            font-weight: 500;
            margin-bottom: 0px;
            margin-top: 20px;
        }
        font-weight: 500;
        text-align: center;
        .neos-nodetypes-text{
            margin: 0 10px;
            display: inline-block;
        }
        .neos-nodetypes-text:first-of-type{
            margin: 0px;
            display: block;
        }
    }
}


.orange-bg, .red-bg {
  background-color: #ea5b0c;

  .neos-alignment-center {
    .img-responsive {
      margin: auto;
    }
  }
}

@media (max-width: $sm-view){
    section{
        h1,h2,h3,h4{
            //text-align: center !important;
        }
        p{
            //text-align: center !important;
        }
    }

    .orange-bg, .red-bg{
      .neos-alignment-center {
        .img-responsive {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }

}
@media (min-width: $sm-view) {
    section, .swiper-container{
        margin-bottom:30px;
    }
}

@media (min-width: $md-view) {
    section, .swiper-container{
        margin-bottom:40px;
    }
}

@media (min-width: $lg-view) {
    section, .swiper-container{
        margin-bottom: 50px;
    }
}
//Vom Slider
.head{
    display: none !important;
}
