.informationIconWrapper{
    text-align: center;
    margin-top:20px;
    border-bottom: 1px solid #cecece;
    i{
        margin-bottom: 20px;
        color: #c70000;
    }
    p{
        font-weight: 500;
        margin-bottom: 20px;
    }
}
.section-inner div[class^="col"]:last-of-type .informationIconWrapper{
    border-bottom: 0px solid #cecece;
    margin-bottom: 0px;
    p{
        margin-bottom: 0px;
    }
}
@media (min-width: $sm-view) {
    .informationIconWrapper{
        border-bottom: 0px solid #cecece;
        border-right: 1px solid #cecece;
        margin-top:30px;
        margin-bottom: 10px;
    }
    .section-inner div[class^="col"]:last-of-type .informationIconWrapper{
        border-right: 0px solid #cecece;
        margin-bottom: 0px;
    }
}

@media (min-width: $md-view) {
    .informationIconWrapper{
        margin-top: 40px;
        margin-bottom:20px;
    }
}

@media (min-width: $lg-view) {
    .informationIconWrapper{
        margin-top: 50px;
        margin-bottom: 30px;
    }
}
