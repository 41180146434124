.navbar{
    &.navbar-default{
        border: 0px solid black;
        background-color: rgba(256,256,256,0.8);
    }
    .navbar-brand{
        height: 50px;
        padding: 8px 15px;

        img{
            height: 34px;
        }
    }
    ul.navbar-nav{
        li{
            a{
                font-size: 10px;
                padding: 20px 2px;
                font-weight: 400;
                text-transform: uppercase;
                text-align: center;
            }
            a:hover{
                font-weight: 800;
                color: #555;
            }

        }
        li.active{
            a{
                font-weight: 800;
            }
        }
    }
    #navbar{
        border: 0px solid black;
    }
    &.navbar-default .navbar-nav>.active>a{
        background-color: transparent;
    }
    .navbar-collapse{
        box-shadow: none;
        max-height: 100% !important;
        height: 10000px;// There must be some other Solution here
    }
    @media (min-width: $sm-view) {
        ul.navbar-nav{
            li{
                a{
                    font-size: 12px;
                    padding: 19px 4px;
                }

                a.homenav {
                   position: relative;
                   margin-right: 60px;
                   display: block;
                }

            }
        }
    }

    @media (min-width: $md-view) {
        .navbar-brand{
            height: 100px;
            padding: 20px;
            padding-left: 0px;
                img{
                    height: 60px;
                }
            }
        #navbar{
            height: 100px !important;
        }
        ul.navbar-nav{
            li{
                a{
                    font-size: 14px;
                    padding: 43px 6px;
                }

                a.homenav {
                   position: relative;
                   margin-right: 60px;
                   display: block;
                }
            }
        }
    }
    @media (min-width: $lg-view) {
        ul.navbar-nav {
            li {
                a {
                    font-size: 14px;
                    padding: 43px 6px;
                }

                a.homenav {
                   position: relative;
                   margin-right: 160px;
                   display: block;
                }
            }
        }
    }
}
//Swiper
    .swiper-container-horizontal>.swiper-pagination{
        text-align: center;
        bottom: 20px;
        top: auto;
    }
    .swiper-pagination-bullet{
        background-color: #9b9d9a;
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active{
        background-color: white;
    }
